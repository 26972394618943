$buttonDefault: darken(#177cba, 12%);

$linkDefault: $buttonDefault;

$themes: (
  green: (
    dark: #005745,
    light: #f7f7f7,
    regular: #00956e,
  ),
  purple: (
    dark: #552956,
    light: #faf7fb,
    regular: #7a437c,
  ),
);

$currentTheme: map_get($themes, green);
$primary: map_get($currentTheme, regular);
$primaryDark: map_get($currentTheme, dark);
$primaryLight: map_get($currentTheme, light);
