@import '../../assets/styles/index';

.provider {
  .avatar-container {
    position: relative;
  }

  .avatar {
    height: 120px;
    margin: 0 auto;
    width: 120px;

    @include breakpoint('sm') {
      height: 75px;
      width: 75px;
    }
  }

  h1 {
    font-size: 24px;
    line-height: normal;
    letter-spacing: normal;
  }

  h2 {
    opacity: 0.8;
    font-size: 15px;
    line-height: 1.47;
    letter-spacing: normal;
    color: #000000;
  }

  .provider-name {
    @include font;
    font-size: 24px;
    font-weight: normal;
    &-link {
      color: $linkDefault;
      font-size: 24px;
      font-weight: normal;
    }
  }

  .provider-title {
    color: darken(#707271, 11%);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
  }

  .default-profile-image {
    border-radius: 50%;
    color: #fff;
    font-size: 55px;
    height: 100px;
    margin: 0 auto;
    padding-top: 10px;
    text-align: center;
    width: 100px;

    @include breakpoint('sm') {
      font-size: 40px;
      height: 75px;
      padding-top: 5px;
      width: 75px;
    }

    @include breakpoint('xs') {
      font-size: 45px;
      height: 85px;
      margin: 0 0 0px;
      width: 85px;
    }
  }

  .share-content {
    margin-top: 15px;
  }

  .facility-name {
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
  }

  .facility-address {
    width: 310px;
    height: 36px;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: normal;
    color: #444444;
  }

  .facility-map-link {
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: #2e5491;
  }

  .facility-numbers {
    width: 100%;
    position: relative;
  }

  .facility-phone-number {
    display: block;
    width: 100%;
  }

  .available-times {
    margin-top: 20px;

    p {
      margin: 0;
    }

    p,
    a {
      font-size: 14px;
    }

    .button-container {
      display: block;
      margin: 10px 0;

      button {
        margin-bottom: 5px;
        margin-right: 5px;
        min-width: 80px;
      }
    }

    @include breakpoint('xs') {
      margin-top: 0;
    }
  }
}
