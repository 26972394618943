@import '../../assets/styles/index';

.ReactModal__Body--open {
  overflow: hidden;
}

.BaseModal {
  position: static;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 0px;
  outline: none;
  padding: 2rem;
  max-height: 75vh;
  overflow-y: scroll;
  width: 684px;

  @include breakpoint('xs') {
    width: calc(100% - 4rem);
  }
}

.BaseModalOverlay {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;
}
