@import '../../assets/styles/index';

.EducationalSearch {
  margin: 0 auto;

  h6 {
    @include font;
  }

  @include breakpoint('xs') {
    margin-bottom: 0;
  }

  .tab-container {
    border-radius: 4px 4px 0 0;

    @include breakpoint('xs') {
      border-radius: 0;
      margin-bottom: 0;
    }

    .tab-item {
      background: transparent;
      border: 0;
      display: inline-block;
      font-size: 18px;
      padding: 10px 0;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }

    .tab-panel {
      &-item {
        margin-right: 15px;
        position: relative;

        @include breakpoint('xs') {
          margin-right: 0;
          width: 50%;

          &:last-child {
            border-right: 0 !important;
          }
        }

        &.active {
          a {
            @include breakpoint('xs') {
              font-weight: bold;
              width: 100%;
            }
          }
        }
      }
    }

    .tab-content {
      background: #fff;
      border-radius: 0 0 4px 4px;
      padding: 35px 0 26px 0;

      @include breakpoint('xs') {
        padding: 0;
      }

      .button-container {
        height: 100%;
        max-height: 56px;
        position: relative;
        width: 100%;

        @include breakpoint('xs') {
          height: 48px;
        }
      }

      .button-search {
        font-size: 24px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @include breakpoint('xs') {
          padding-bottom: 3px;
          padding-top: 0px;
        }
      }

      .button-icon {
        display: inline-block;

        @include breakpoint('xs') {
          display: none;
        }
      }

      .button-text {
        display: none;

        @include breakpoint('xs') {
          font-size: 18px;
          display: inline-block;
        }
      }

      .disclaimer {
        font-size: 0.8rem;
        margin-bottom: 0;
      }

      .form-content {
        @include breakpoint('xs') {
          padding: 20px 20px 0 20px;
          width: 100%;
        }
      }
    }
  }

  .filter-mobile {
    background: #f2f6f5;
    font-size: 14px;
    padding: 15px;
    width: 100%;
    z-index: 2;

    .filter-mobile-wrapper {
      display: flex;
    }

    a,
    button {
      margin-right: 10px;
      padding: 7px 15px;
      white-space: nowrap;

      &.search {
        background: transparent;
        border: 1px solid transparent;
      }

      &.filled {
        color: #fff;
      }

      &.inverse {
        background: transparent;
      }

      &.icon {
        padding: 5px 10px;
      }
    }
  }
}
