@import '../../assets/styles/index';

.EducationalKeywords {
  padding: 15px 20px;
  min-height: 220px;
  text-align: left;

  @include breakpoint('xs') {
    //background: transparent;
    min-height: auto;
    //padding: 0;
  }

  h4,
  .keywords-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;

    @include breakpoint('xs') {
      padding: 15px 0;
    }
  }

  p {
    font-size: 0.82rem;
    margin-bottom: 10px;
    padding-right: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    font-size: 20px;
  }

  .read-more {
    font-size: 14px;
    text-align: right;
    width: 100%;

    button {
      background: transparent;
      border: 1px solid $primaryDark;
      color: $primaryDark;
      padding: 5px;
    }
  }
}
