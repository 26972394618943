.provider-name {
  color: $primaryDark;
  font-size: 24px;

  @include breakpoint('xs') {
    font-size: 18px;
  }
}

.provider-details {
  font-size: 14px;

  p {
    margin: 0;
  }
}

.provider-title {
  font-size: 15px;
}

.share-content {
  padding-right: 15px;

  span {
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
  }
}

.contact-type {
  display: inline-block;
  margin-right: 5px;
}
