@import 'assets/styles/index';

body {
  background-color: #f1f1f1 !important;
  margin: 0;
  @include font;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inputElipsis label {
  top: -6px;
  width: 80%;
}
@media (max-width: 380px) {
  .inputElipsis label {
    top: -15px;
    width: 80%;
  }
}

.sticky-wrapper {
  background: #fff;
  min-width: 100%;
  z-index: 10 !important;
}

.sticky {
  z-index: 10 !important;
  background: #fff;
}
