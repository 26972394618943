@import '../../assets/styles/index';

.map {
  @include breakpoint('xs') {
    margin-top: 15px;
  }
}

.map-link {
  color: $linkDefault;
  font-weight: bold;
}
