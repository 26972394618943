@mixin termsLayout {
  h1 {
    color: $primaryDark;
    margin-bottom: 30px;
  }

  .terms {
    margin-top: 40px;

    h3 {
      border-bottom: 1px solid $primaryDark;
      color: $primaryDark;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
  }
}