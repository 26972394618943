.LandingPage {
  background: #f1f1f1;
  padding: 0px 0 30px;

  .bubble-container {
    text-align: center;
  }

  hr {
    &.vertical-stroke {
      height: 94px;
      margin-top: 50px;
      width: 1px;
    }

    &.horizontal-stroke {
      height: 1px;
      width: 100%;
    }
  }

  .section-heading {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
  }

  .icon-avatar {
    height: 80px;
    margin: 0 auto;
    width: 80px;
  }

  .icon-text {
    hyphens: auto;
    font-size: 0.75rem;
    line-height: 1.2rem;
    margin-top: 10px;
    text-align: center;
  }

  button {
    background: transparent;
    border: 0;
  }

  a {
    display: inline;
    text-align: center;
    color: initial;

    &:hover {
      color: initial;
    }
  }

  svg {
    position: absolute;
  }
}