@import '../../assets/styles/index';

.ScreeningQuestionModal {
  h4, &__question-content {
    margin-bottom: 1rem;
  }
    
  h4 {
    font-weight: bold;
  }

  &__button {
    width: 100px;
    &:first-child {
      margin-right: 1rem;
    }
  }
}