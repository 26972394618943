@import '../../assets/styles/index';

.EducationalResult {
  @include breakpoint('xs') {
    margin-bottom: 25px;
  }

  a {
    color: $linkDefault;
  }

  .provider-more-doctors-link {
    color: $linkDefault;
    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    padding: 0;
    appearance: none;
    border: none;
    text-transform: none;
  }

  @include alertInfo;
}
