@import '../../assets/styles/index';

.EducationalScheduleCard {
  box-shadow: 0 2px 6px rgba(#000, 0.3);
  position: relative;
  width: 100%;
  z-index: 2;
  min-height: 220px;
  text-align: left;

  .grid-container {
    align-items: center;

    @include breakpoint('xs') {
      align-items: flex-start;
    }
  }

  .schedule-container {
    @include breakpoint('xs') {
      border-bottom: 1px dashed #999;
      padding: 20px 0;
    }
  }

  .btn-plain {
    @include breakpoint('xs') {
      font-size: 18px;
      text-decoration: none;
    }
  }

  @include breakpoint('xs') {
    box-shadow: 0 0 0 rgba(#000, 0);
    padding-top: 10px;
  }

  .img-row {
    @include breakpoint('xs') {
      text-align: center;
    }
  }
}
