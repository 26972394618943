@import '../../assets/styles/index';

.ScheduleDetailPage {
  .MuiBreadcrumbs-ol li {
    color: #000;
  }

  .MuiBreadcrumbs-ol a {
    color: darken($linkDefault, 15%);
  }
  .service-name-header {
    @include font;
    color: $primaryDark;
    font-size: 24px;
    font-weight: normal;
  }

  .name-link {
    @include font;
    color: $linkDefault;
    font-size: 24px;
    font-weight: normal;
  }

  min-height: 50vh;
  .Avatar {
    height: 120px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;

    @include breakpoint('xs') {
      height: 85px;
      width: 85px;
    }
  }

  .default-profile-image {
    border-radius: 50%;
    color: #fff;
    font-size: 96px;
    height: 120px;
    line-height: 1.2;
    margin: 0 auto 20px;
    text-align: center;
    width: 120px;

    @include breakpoint('sm') {
      font-size: 40px;
      height: 75px;
      width: 75px;
    }

    @include breakpoint('xs') {
      font-size: 45px;
      height: 85px;
      margin: 0 0 20px;
      width: 85px;
    }
  }

  .sidebar {
    background: #f7f7f7;
    .LocationDetail {
      margin: 0 15px 15px;
    }
  }
}
