$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black:    #000;

$blue:    #2780E3;
$indigo:  #6610f2;
$purple:  #613d7c;
$pink:    #e83e8c;
$red:     #FF0039;
$orange:  #F2661F;
$yellow:  #BED32B;
$green:   #3FB618;
$teal:    #20c997;
$cyan:    #9954BB;
$lightblue: lighten($blue, 20%);

$primary:       $blue;
$secondary:     $orange;
$success:       $green;
$info:          $lightblue;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

@import "~bootswatch/dist/cosmo/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootswatch/dist/cosmo/_bootswatch.scss";
$fa-font-path: '../fonts';
@import '~font-awesome/scss/font-awesome';
@import '~formiojs/dist/formio.full.css';

.logo {
  height: 1.9em;
}

.text-blue {
  color: #127ABF;
}

.text-green {
  color: #449414;
}

.hero-image {
  display: inline;
  height: 2em;
}

#main {
  margin-top: 10px;
}

[id^=error-list-].alert {
  display: none;
}

.formio-component-form {
  input, select, textarea, .formio-component-select .form-control {
    border-radius: 6px;
  }

  .formio-component-datetime {
    input {
      border-bottom-left-radius: 6px !important;
      border-top-left-radius: 6px  !important;
    }
  }
}

.formio-component-checkbox {
  .form-check-label {
    align-items: center;
    display: flex;
    flex-direction: row;

    input, .form-check-input {
      height: 18px;
      margin-left: -1.45rem;
      margin-top: 0;
      width: 18px;
    }

    span {
      font-size: 14px;
      line-height: 1;
    }
  }
}

.formio-component-day {
  .col-form-label {
    margin-bottom: 20px;
  }

  .col {
    position: relative;

    label {
      background: #fff;
      font-size: 12px;
      left: 20px;
      padding: 0 5px;
      position: absolute;
      top: 0;
      transform: translateY(-10px);
      z-index: 10;
    }

    input, select {
      border-color: #979797;
      height: 53px;

    }
  }
}

.formio-component-radio {
  margin-bottom: 30px !important;
}

.formio-component-select {
  margin-bottom: 30px !important;

  .form-control {
    border-color: #979797;
    height: 53px;
  }
}

.formio-component-textarea {
  margin-bottom: 30px;

  textarea.form-control {
    border-color: #979797;
    height: 113px;
    outline: none;

    &:focus {
      border-color: #000;
    }

    &.is-invalid {
      border-color: #dc3545;
    }
  }
}

.formio-component-submit .help-block {
  color: #dc3545;
  font-size: 14px;
}

.formio-component-select, .formio-component-textarea, .formio-component-textfield, .formio-component-email, .formio-component-datetime, .formio-component-number, .formio-component-phoneNumber {
  position: relative;

  label {
    background: #fff;
    color: rgba(#000, 0.54);
    font-size: 12px;
    left: 10px;
    line-height: 0.5;
    position: absolute;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    top: 0;
    transform: translateY(-9px);
    z-index: 10;
  }

  .form-text.text-muted {
    color: #000;
    font-size: 12px;
  }

  &.formio-component-referring-facility {
    height: 0;
    margin-bottom: 0 !important;
    overflow: hidden;
    visibility: hidden;
  }
}

.formio-component-textfield, .formio-component-email, .formio-component-datetime, .formio-component-number, .formio-component-phoneNumber {
  margin-bottom: 30px !important;

  input.form-control {
    border-color: #979797;
    height: auto;
    outline: none;
    padding: 13.5px 14px;

    &:focus {
      border-color: #000;
    }

    &.is-invalid {
      border-color: #dc3545;
    }
  }
}
