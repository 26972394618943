@import '../../assets/styles/index';

.InventoryControls {
  .DateCarouselContainer {
    background: white;
    border-bottom: 1px solid grey;
    padding: 10px 0;
    border-top: 1px solid grey;
  }
  .view-button {
    display: flex;
    align-items: center;
    color: $linkDefault;
  }
  .date-row {
    text-align: center;

    .icon {
      color: rgba(#000, 0.5);
    }

    > * {
      padding: 20px 0;
    }
  }
}
