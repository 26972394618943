@import '../../assets/styles/index';
.VisitCancelConfirmationPage {
  padding: 30px 30px;

  h2 {
    font-weight: bold;
  }

  a,
  a:hover {
    color: $linkDefault;
  }
}
