@import '../../assets/styles/index';

.Location {
  background: #fff;
  padding: 15px 0;
  .inventoryhead{
    margin-top: -10px !important;
  }
  .facilityhead{
    margin-top: -13.5px !important;
  }

  h5,
  .heading {
    color: #222222;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1.15rem;
  }

  p {
    color: #222222;
    font-size: 14px;
    margin: 0;
    line-height: 1.33;
  }

  span {
    color: rgba(#444, 0.7);
    font-size: 14px;
  }

  a {
    font-size: 14px;
  }

  img {
    width: 100%;
  }
  .location-address {
    line-height: 1.5;
    font-size: 0.87rem;
  }
  .location-name {
    line-height: 1.5;
    font-size: 0.87rem;
    color: #222222;
    font-weight: bold;
  }
}
