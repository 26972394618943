@import '../../assets/styles/index';

.PhysicianServices {
  border-radius: 4px;
  // margin-bottom: 30px;

  .banner-root {
    min-height: 100px;
  }

  .banner-title {
    font-size: 24px;
    margin: 0;
    padding-left: 10px;
  }

  ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 10px;

    li {
      margin-top: 5px;
    }
  }

  .service-image-element-wrapper {
    height: 100%;
    margin-left: -12px;
    overflow: hidden;
    position: relative;
    width: 100%;
    -webkit-transform: skew(-6deg);
    -moz-transform: skew(-6deg);
    -o-transform: skew(-6deg);
    transform: skew(-6deg);

    @include breakpoint('xs') {
      border-radius: 50%;
      height: 85px;
      margin: 0 auto;
      width: 85px;
      -webkit-transform: skew(0deg);
      -moz-transform: skew(0deg);
      -o-transform: skew(0deg);
      transform: skew(0deg);
    }

    .service-image-element {
      background-repeat: no-repeat;
      background-size: 100% auto;
      color: #fff;
      font-size: 130px;
      height: 100%;
      left: 12px;
      position: absolute;
      top: 0px;
      width: 100%;
      z-index: 1;
      -webkit-transform: skew(6deg);
      -moz-transform: skew(6deg);
      -o-transform: skew(6deg);
      transform: skew(6deg);

      @include breakpoint('xs') {
        font-size: 45px;
        left: 0;
        position: relative;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
        transform: skew(0deg);
      }
    }
  }
}
