@import '../../assets/styles/index';

.SchedulePicker {
  background: #fff;
  box-shadow: 0 3px 6px rgba(#000, 0.4);
  width: 508px;
  z-index: 3;

  .tab-button {
    background: #fff;
    border-top: 0;
    border-left: 0;
    font-size: 18px;
    outline: none;
    padding: 23px 0;
    width: 100%;
  }

  .schedule-content {
    padding-bottom: 55px;
    position: relative;
  }

  .today-picker {
    h2 {
      font-weight: bold;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-100%);
      width: 100%;
    }
  }

  .time-picker {
    padding: 50px 30px 0;

    p {
      margin: 0;
    }
  }

  .actions {
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    position: absolute;
    width: 100%;

    button {
      border: 0;
      border-radius: 4px;
      font-size: 18px;
      margin-right: 5px;
      outline: none;
      padding: 5px 25px;
    }
  }
}
