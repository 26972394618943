.provider {
  @media (max-width: 440px) {
    .DateCarousel {
      padding: 0px;
      align-items: center;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .DateCarouselContainer .dates,
  .DateCarousel .dates {
    width: 800px;
    overflow: scroll;
    flex-wrap: unset;
    justify-content: flex-start !important;
  }
  .DateCarouselContainer .date-column.empty {
    min-width: 72.66px !important;
  }
}
