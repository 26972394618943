@import '../../assets/styles/index';

.Appointment {
  width: 75px;
  display: inline-block;

  .appointment-button {
    &.btn-default {
      color: white;
    }
    border-radius: 0.2rem;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.25rem;
    text-align: center;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    vertical-align: middle;
    width: 100%;
    -webkit-appearance: button;
  }
}

.AppointmentDisabled {
  width: 75px;
  display: inline-block;
  background-color: lightgray;
  pointer-events: none;

  .appointment-button {
    &.btn-default {
      color: white;
    }
    border-radius: 0.2rem;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.25rem;
    text-align: center;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    vertical-align: middle;
    width: 100%;
    -webkit-appearance: button;
  }
}
