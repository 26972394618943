@import '../../assets/styles/index';

.EducationalImage {
  height: 220px;
  margin-left: -12px;
  overflow: hidden;
  position: relative;
  width: 190px;
  -webkit-transform: skew(-6deg);
  -moz-transform: skew(-6deg);
  -o-transform: skew(-6deg);
  transform: skew(-6deg);

  @include breakpoint('md') {
    width: 160px;
  }

  @include breakpoint('sm') {
    width: 120px;
  }

  @include breakpoint('xs') {
    border-radius: 50%;
    height: 85px;
    margin: 0 auto;
    width: 85px;
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -o-transform: skew(0deg);
    transform: skew(0deg);
  }

  .educational-image-element {
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: #fff;
    font-size: 130px;
    height: 100%;
    left: 12px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1;
    -webkit-transform: skew(6deg);
    -moz-transform: skew(6deg);
    -o-transform: skew(6deg);
    transform: skew(6deg);

    svg {
      margin-left: 22px;

      @include breakpoint('xs') {
        margin-left: 0;
        margin-top: 18px;
      }
    }

    @include breakpoint('xs') {
      font-size: 45px;
      left: 0;
      position: relative;
      -webkit-transform: skew(0deg);
      -moz-transform: skew(0deg);
      -o-transform: skew(0deg);
      transform: skew(0deg);
    }
  }
}
