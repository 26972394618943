@import '../../assets/styles/index';

.ProviderDetailSidebar {
  .sidebar-title {
    align-items: center;
    background-color: $primaryDark;
    color: #fff;
    display: flex;
    height: 55px;
    padding-left: 0.9375rem;
    padding-right: 1rem;

    h5 {
      margin: 0;
      width: 75%;
    }

    button {
      background: $primaryDark;
      border: 0;
      color: #fff;
    }
  }

  .sidebar-content {
    padding: 0.9375rem;
  }
}