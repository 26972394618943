@import '../../assets/styles/index';

.ProviderNoAvailability {
  p {
    padding: 20px;
    padding-top: 40px;
  }

  div {
    display: flex;
    justify-content: center;
  }
}
