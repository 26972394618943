@import '../../assets/styles/index';

.VisitCancelPage {
  //margin-top: 30px;
  margin-bottom: 30px;

  h2 {
    font-weight: bold;
  }

  .ViewMore {
    font-weight: bold !important;
    padding-inline: 5px !important;
    color: $linkDefault !important;
  }

  .DateCarousel.horizontal .DateHeading {
    margin: 0px !important;
    padding-top: 5px;
  }

  .more {
    width: 75px !important;
    border-radius: 2px !important;
  }

  .DateCarousel {
    margin-left: -24px;
    justify-content: flex-start;

    .datesMobile {
      padding-top: 0px;
    }

    .dates {
      justify-content: center;
      padding-right: 5px;
    }
  }

  .DateCarouselContainer {
    padding-bottom: 0px;
  }

  .DateHeading .month-day {
    margin-right: 10px;
  }

  .Appointment.Link {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  @media (min-width: 600px) and (max-width: 770px) {

    .DateCarouselContainer .dates,
    .DateCarousel .dates {

      overflow: auto;
      flex-wrap: unset;
      justify-content: flex-start !important;
      margin-left: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 1040px) {

    .DateCarouselContainer .dates,
    .DateCarousel .dates {
      overflow: auto !important;
      flex-wrap: unset;
      justify-content: flex-start !important;
    }
  }

  .CalendarIconM {
    position: absolute;
    right: 15%;

    @media (orientation:landscape) and (max-width: 844px) {
      right: 25%;
    }
  }

  .ProviderDate {
    text-align: left !important;
    padding-top: 15px !important;
  }

  .times-container {
    margin-top: 10px;

    h5 {
      color: $primaryDark;
      font-weight: bold;
      text-transform: uppercase;
    }

    a {
      margin-bottom: 5px;
      margin-right: 8px;
    }
  }

  .time-container {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 15px;

    &-button {
      flex-shrink: 0;
    }
  }

  .typography-title {
    margin-top: 5px;
  }

  .noAvailableAppointments {
    margin: 20px auto;
  }

  @media (min-width: 1040px) and (max-width: 1200px) {
    .MuiGrid-grid-sm-6 .MuiGrid-container.MuiGrid-spacing-xs-1 {
      width: 586px;
    }
  }

  @media (max-width: 767px) {
    .MuiGrid-grid-sm-6 .MuiGrid-container.MuiGrid-spacing-xs-1 {
      width: 22rem;
    }
  }

  @media (min-width: 995px) {
    .typography-title {
      margin-left: 28px;
    }
  }

  @media (min-width: 600px) and (max-width: 770px) {
    .MuiGrid-grid-sm-1 {
      flex-basis: 0;
    }
  }

  @media (min-width: 1040px) and (max-width: 1200px) {
    .MuiGrid-grid-xs-10 {
      flex-basis: 80%;
    }
  }

  .mb-4 {
    margin-bottom: 0px !important;
  }

}