.DateHeading {
  display: inline-grid;
  width: 80px;

  &.vertical {
    text-align: center;
  }

  &.horizontal {
    text-align: left;
    grid-template-columns: auto auto;
  }

  .day {
    font-weight: normal;
  }

  .month-day {
    font-weight: bold;
  }
}
