.DateCarousel {
  justify-content: center;
  background: #fff;
  padding: 15px 0;

  &.horizontal {
    .DateHeading {
      margin: 0 10px;
    }
  }
  svg {
    cursor: pointer;
  }

  .disabled {
    cursor: not-allowed;
    color: darkgray;
    svg {
      cursor: not-allowed;
    }
  }

  .control {
    text-align: center;
  }

  .dates {
    justify-content: center;
    font-size: 13px;
    padding-top: 1px;
    padding-left: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px;
    flex-wrap: nowrap;
  }
  .datesMobile {
    text-align: center;
    padding-top: 8px;
    padding-left: 12px !important;
  }
}
