@import '../../assets/styles/index';

.ScheduleAppointment {
  font-size: 15px;

  .Mui-error {
    fieldset {
      border-color: #cc0000 !important;
    }
  }

  label.Mui-error,
  p.Mui-error {
    color: #cc0000;
  }

  .MuiBreadcrumbs-ol li {
    color: #000;
  }

  .MuiBreadcrumbs-ol a {
    color: darken($linkDefault, 15%);
  }

  .errors {
    color: #cc0000;
    background-color: #f5f5f5;
    padding-bottom: 10px;

    .errors-wrapper {
      background-color: #ffffff;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      padding: 10px 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      display: flex;
      align-items: center;
      font-weight: bold;
      div {
        margin-left: 1em;
        margin-top: 3px;
      }
    }
  }

  a {
    color: $linkDefault;
  }

  h1 {
    font-size: 24px;
    font-weight: normal;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
  }

  .provider-title {
    font-size: 15px;
  }

  .facility-container {
    list-style-type: none;
    padding: 0;
  }

  .form-section {
    border-top: 1px solid black;
    padding: 20px 0 25px;

    &_row {
      margin: 20px 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  h5 {
    font-weight: normal;
    margin-bottom: 1.15rem;
  }

  form {
    .container {
      padding: 0;
    }

    .row {
      margin-bottom: 20px;
    }

    .required {
      color: darken(#b71c1c, 5%);
    }

    .label {
      font-size: 13px;
    }

    .label-question {
      font-size: 15px;
      font-weight: bold;
    }

    .text {
      font-size: 15px;
      font-weight: bold;
    }

    .form-item {
      @include breakpoint('xs') {
        margin-bottom: 20px;
      }
    }

    button {
      &.submit-btn {
        &:disabled {
          background: rgba(#000, 0.25);
          border-color: transparent;
          cursor: text;
        }

        @include breakpoint('xs') {
          display: block;
          width: 100%;
        }
      }

      &:not(.btn-plain) {
        padding: 16px 48px;
      }
    }
  }
}
