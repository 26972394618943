h1, h2, h3, h4, h5, h6 {
  @include fontDemi;
}

a {
  &.link-default {
    color: $linkDefault;
  }

  &.link-blk {
    color: #000;
    text-decoration: underline;

    &.link-tdn {
      text-decoration: none;


      &:hover {
        text-decoration: underline;
      }
    }
  }
}

button, a {
  &.btn-plain {
    color: $linkDefault;
    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    padding: 0;
    appearance: none;
    border: none;
  }

  &.btn-default {
    background: $buttonDefault;
    border-color: $buttonDefault;
    color: #fff;
  }

  &.btn-default-outline {
    background-color: transparent;
    border-color: $buttonDefault;
    color: $buttonDefault;

    &:hover {
      background: $buttonDefault;
      border-color: $buttonDefault;
      color: #fff;
    }
  }

  &.filter-btn {
    padding: 7px 15px;
    white-space: nowrap;

    &.filled {
      border: 0;
    }

    &.inverse {
      background: transparent;
    }
  }
}

@mixin alertInfo {
  .alert-info {
    color: #0a4860;
  }
}