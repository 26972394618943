@import '../../assets/styles/index';

.ConfirmationPage {
  font-size: 15px;
  padding: 30px 0;

  @include breakpoint('xs') {
    padding: 30px 15px;
  }
  .react-add-to-calendar {
    width: 100%;
  }
  .selected {
    margin-right: 10px;
  }
  .confirmation {
    font-size: 22px;
  }

  .confirmation-heading {
    color: #444444;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5rem;

    &.important {
      color: darken(#cc0000, 7%);
    }
  }

  hr {
    border-top: 2px dotted rgba(#000, 0.15);
  }

  button {
    margin-right: 10px;
    font-size: 13px;
    font-weight: bold;
    border-radius: '4px';

    .react-add-to-calendar {
      position: relative;
      display: inline-block;
      margin: 0 auto;

      &__button {
        &:hover {
          text-decoration: none;
        }
        &:active {
          text-decoration: none;
        }
      }

      &__icon {
        &--right {
          padding-left: 5px;
        }

        &--left {
          padding-right: 5px;
        }
      }

      .react-add-to-calendar__dropdown {
        position: absolute;
        top: 30px;
        left: 1px;
        width: 100%;
        padding: 5px 0 5px 8px;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
        text-align: left;
        ul {
          list-style: none;
          margin: 0;
          padding-inline-start: 10px;

          li {
            a,
            a:hover {
              color: $linkDefault;
              text-decoration: none;
              i {
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  img {
    width: 100%;
  }
}
