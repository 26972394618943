.App {
  background: #fff;
}

.locizeHeaderStyle {
  font-family: "AvenirNextLTPro", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  padding-block: 8px;
}

.locizePTagStyle {
  line-height: 1.5;
  font-size: .87rem;
  color: #222;
  padding-block: 8px;
}

.locizeContainer {
  background-color: #f7f7f7;
  //box-shadow: 0px 8px 6px -6px #d1d1d1;
  width: 100%;
  padding-block: 10px;
}

// @media (min-width: 768px) {
//   .MuiTabs-flexContainer {
//     justify-content: center;
//   }
// }