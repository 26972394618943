@font-face {
  font-family: 'AvenirNextLTPro-Light';
  src: url("../../fonts/Linotype - AvenirNextLTPro-Light.otf");
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url("../../fonts/Linotype - AvenirNextLTPro-Regular.otf");
}

@font-face {
  font-family: 'AvenirNextLTPro-Medium';
  src: url("../../fonts/Linotype - AvenirNextLTPro-Medium.otf");
}

@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url("../../fonts/Linotype - AvenirNextLTPro-Demi.otf");
}

@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url("../../fonts/Linotype - AvenirNextLTPro-Bold.otf");
}

$fallback: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif";

@mixin font {
  font-family: 'AvenirNextLTPro', $fallback;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin fontDemi {
  font-family: 'AvenirNextLTPro-Demi', $fallback;
  font-style: normal;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
