@import '../../assets/styles/index';

.MapView {
  margin: 8px;
  // "Map" / "Satellite" controls
  .gmnoprint {
    display: none;
  }
  // Footer report a map error
  .gm-style-cc {
    display: none;
  }
  // InfoWindow
  .gm-style-iw-t {
    bottom: 56px !important;
  }

  .gm-style .gm-style-iw-c {
    font-size: 1.4em;
    border-radius: 0;
    min-width: 200px;

    .infoWindowWrapper {
      display: flex;
      flex-direction: column;

      .providerInformation {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        span {
          color: #666;
          font-size: 0.8em;
        }

        a {
          font-weight: bold;
        }
      }

      .locationInformation {
        display: flex;
        flex-direction: column;

        .locationName {
          font-weight: bold;
        }
      }
    }
  }
}
