@import '../../assets/styles/index';

.results-pagination {
  align-items: center;
  display: flex;
  margin: 35px 0;

  .pagination-link {
    cursor: pointer;
    height: 36px;
    margin-right: 8px;
    padding: 6px;
    text-align: center;
    width: 36px;
    border-radius: 0.2rem;

    &:not(.active) {
      display: block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.25rem;
      text-align: center;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      user-select: none;
      vertical-align: middle;
      -webkit-appearance: button;
    }
  }

  .pagination-link.chevron {
    background-color: transparent;
    border: 0;
  }

  .pagination-link.disabled {
    cursor: text;
    opacity: 0.3;
  }

  .pagination-link.pagination-page {
    border: 1px solid $linkDefault;
    color: $linkDefault;
  }

  .pagination-link.pagination-page.empty {
    border: 0;
    cursor: text;
  }

  .pagination-link.pagination-page.active {
    color: #fff;
    cursor: text;
  }
}
