.EducationalAlert {
  text-align: right;
  .custom-alert {
    border-radius: 0;
    display: inline-block;
    font-size: 15px;
    margin-bottom: 0;
    padding: 0.25rem 1.25rem;
    z-index: 1;

    svg {
      margin-right: 10px;
    }
  }
}
