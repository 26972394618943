@import '../../assets/styles/index';

.provider-more-doctors-link {
  text-align: right;
  color: $linkDefault;
  text-decoration: underline;
  cursor: pointer;
  background-color: white;
  // These sometimes get overriden by other styles so you
  // may need to set styles again in a parent component
  padding: 0;
  appearance: none;
  border: none;
  text-transform: none;
}
