.InventoryDateCarousel {
  padding: 0;
  align-items: baseline;
  text-align: center;
  .DateHeading {
    width: auto;
  }
  .dates {
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-left: 25px !important;
  }
}
