@import '../../assets/styles/index';

.VisitProhibitedPatient {
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;

  h1 {
    font-size: 4.5rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  p {
    color: rgba(#000, 0.6);
  }
}
