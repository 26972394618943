@import '../../assets/styles/index';

.Footer {
  background: #f1f1f1;
  border-top: .0625rem solid #dbdbdb;
  color: $primaryDark;
  font-size: 0.875rem;
  padding: 20px 0;

  @include breakpoint('xs') {
    padding-bottom: 100px;
  }

  .footer-link {
    color: $primaryDark;
    opacity: 0.8;
    text-decoration: underline;
    background: none;

  }

  .footer-logo {
    background: $primaryDark;
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.625rem;
    height: 40px;
    padding: 12px 0;
    text-align: center;
    width: 40px;
  }

  .footer-logo-text {
    color: $primaryDark;
    font-weight: bold;
  }

  .facility-name, .facility-number {
    margin: 0;
  }

  .facility-name {
    font-size: 0.75rem;
  }

  .facility-number {
    color: $primaryDark;
    font-weight: bold;
  }

  .footer-bottom {
    font-size: 0.875rem;
    margin-top: 1.95rem;
    padding: 0;

    p {
      line-height: 2;
      margin: 0;
      opacity: 0.8;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;

      &:first-child:before {
        content: '';
      }

      &:before {
        content: '\00a0\2022\00a0\00a0';
        color: rgba(#000, 0.5);
        font-size: 0.625rem;
      }
    }
  }
}
