@import '../../assets/styles/index';

.ResultListLocation {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &:last-of-type {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .facility-container {
    color: #444;
    list-style-type: none;
    padding: 0;

    li:first-of-type {
      margin-bottom: 10px;
    }

    @include breakpoint('xs') {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .contact-type {
    color: #444;
    font-size: 14px;
  }
}
