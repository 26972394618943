.DateCarouselContainer {
  background-color: white;
  padding-bottom: 15px;
  .dates {
    text-align: left;
    position: relative;
    margin-left: -22px;
  }
  .date-column {
    position: relative;
    width: 80px;
    text-align: center;
    padding: 0.25rem 0.25rem;
    .Appointment {
      margin-bottom: 0.25rem;
    }
    &.empty {
      align-self: center;
      height: 100%;
      display: flex;
      border: 0;
      border-radius: 3.2px;
      background-clip: border-box;
      background-color: rgba(0, 0, 0, 0.15);
      width: 70px;
      margin: 0 5px;
    }
    .no-times-available {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      align-self: center;
    }
  }
}
.noAvailableAppointments {
  text-align: center;
  padding: 8px 16px;
  margin: 70px auto;
  background-color: rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  border-radius: 3.2px;
}
