@import '../../assets/styles/index';
.AvailableTimesToday {
  .title {
    margin: 0;
    font-size: 14px;
  }

  .button-container {
    display: inline-flex;
    margin: 10px 0;
    width: 100%;
    &.flex-wrap {
      flex-wrap: wrap;
    }
    .cell-separator {
      min-width: 100%;
    }
    .cell {
      flex-direction: column;
      display: flex;
    }
    .date {
      font-size: 14px;
      min-height: 24px;
      width: 100%;
    }
    .Appointment {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
.AvailableTimesToday .date-column.empty {
  width: 100% !important;
  justify-content: center;
}
