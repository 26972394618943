@import '../../assets/styles/index';

.ProviderAvatarServiceLine {
  svg {
    width:90%;
    height:90%;
    //margin-left: 7.5px;
    //margin-top: 9px;
  }
  //height: 80px !important;
  margin: 0 auto;
  border-radius: 50%;
  width:95%;
  aspect-ratio: 1 !important;
 }
