@import '../../assets/styles/index';

.result {
  padding: 20px;

  .providerhead{
    margin-top:15px;
  }

  .avatar {
    height: 100px;
    margin: 0 auto 20px;
    width: 100px;

    @include breakpoint('sm') {
      height: 50px;
      width: 50px;
    }
  }
  .provider-name {
    color: $linkDefault;
    font-size: 24px;
    font-weight: bold;
  }

  .service-name {
    color: #222222;
    font-size: 0.87rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.5;
  }

  .appointment-type-select {
    margin-bottom: 15px;
  }
}
