.ZipcodeAutocompleteOptions {
    z-index: 10;
    position: absolute;
    background-color: white;
    // matches material/ui box shadow for select
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 4px;

    div {
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.08)
        }
    }

}