@import '../../assets/styles/index';

.CalendarIconM {
  position: absolute;
  right: 15%;

  @media (orientation:landscape) and (max-width: 844px) {
    right: 25%;
  }
}

.ProviderDate {
  text-align: left !important;
  padding-top: 15px !important;
}

.ProviderLocationMobile {
  border-bottom: 2px dashed #ddd;
  border-top: 2px dashed #ddd;
  margin: 20px 0;
  padding: 20px 0;
  font-size: 14px;

  .btn-container {
    display: grid;
    text-align: center;
  }

  a {
    color: $linkDefault;
  }

  .filter-btn {
    margin-bottom: 10px;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    color: $buttonDefault;
  }

  .address-content {
    p {
      color: $primaryDark;
      margin: 0;

      &:first-of-type {
        font-weight: bold;
      }
    }
  }

  .times-container {
    margin-top: 10px;

    h5 {
      color: $primaryDark;
      font-weight: bold;
      text-transform: uppercase;
    }

    a {
      margin-bottom: 5px;
      margin-right: 8px;
    }
  }

  .time-container {
    display: flex;
    overflow-x: scroll;

    &-button {
      flex-shrink: 0;
    }
  }

  h5,
  .heading {
    color: #222222;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1.15rem;
  }
}