@import '../../assets/styles/index';

.inventory-filter {
  header {
    align-items: center;
    display: flex;
    height: 72px;
    padding-left: 0.9375rem;
    padding-right: 1rem;

    h5,
    .filter-heading {
      margin: 0;
      width: 75%;

      @include breakpoint('sm') {
        font-size: 1rem;
      }
    }
  }

  article {
    padding-left: 0.9375rem;
    padding-right: 1rem;
    padding-top: 2rem;
    position: relative;
  }

  .filter {
    margin-bottom: 2rem;

    label {
      margin-bottom: 0;
    }

    .show-all-links {
      font-size: 0.875rem;
      text-decoration: underline;
    }
  }

  .filter-mobile {
    background: #fff;
    bottom: 0;
    box-shadow: 0 -3px 6px rgba(#000, 0.1);
    font-size: 14px;
    left: 0;
    padding: 15px;
    position: fixed;
    width: 100%;
    z-index: 101;

    .filter-mobile-wrapper {
      display: flex;
    }

    button {
      border: 0;
      color: #fff;
      margin-right: 10px;
      padding: 7px 15px;
      white-space: nowrap;

      &.inverse {
        background: transparent;
      }

      &.icon {
        padding: 5px 10px;
      }
    }
  }
}
