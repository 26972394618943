.MuiInputBase-input {
    border-radius: 12px !important;
}


.MuiFormLabel-asterisk {
    color: red;
}

.pageNameStyle {
    font-size: 18px !important;
    color: #373A3C !important;
    font-weight: 700 !important;
}

.stepStyle {
    font-size: 14px !important;
    color: #222 !important;
    font-weight: 600 !important;
}

.validation-page .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 100%
}

/* ENDLESS ROTATE */
.rotateLoader{
    animation: rotate 1.5s linear infinite; 
  }
  @keyframes rotate{
    to{ transform: rotate(360deg); }
}

.templatesPage{

    p {
        margin-bottom: 12px;
    }

    .table-bordered th, .table-bordered td {
        border: 0px solid #dee2e6;
    }

    .table th, .table td {
        vertical-align: middle;
    }

    .table-bordered {
        border: 0px solid #dee2e6;
    }

    .formio-button-remove-row {
        color: #fff;
        background-color: #fff;
        border-color: #fff;
        background-image: url('../../assets/images/icons/delete.svg') !important;
        background-repeat: no-repeat;
        background-size: contain;
        height: 24px; 
    }

    .formio-button-remove-row i:before {
        content: none;
    }

}
  
  