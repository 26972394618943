@import '../../assets/styles/index';

.ProviderDetailPage {
  .MuiBreadcrumbs-ol li {
    color: #000;
  }

  .MuiBreadcrumbs-ol a {
    color: darken($linkDefault, 15%);
  }

  .provider-share-profile {
    bottom: -60px;
    left: 50%;
    position: absolute;
    transform: translateX(-84%);
  }

  .provider-container {
    padding-left: 15px;

    @include breakpoint('xs') {
      padding-left: 0;
    }
  }

  .provider-title,
  .contact-type {
    font-size: 0.87rem;
  }

  .contact-type {
    display: inline-block;
    margin-right: 5px;
  }

  .section-title {
    border-bottom: 1px solid $primaryDark;
    color: $primaryDark;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    padding-bottom: 10px;
  }

  .section-description {
    white-space: pre-line;
  }

  .education-title {
    font-weight: bold;
    margin-bottom: 0;
  }

  .insurance-list {
    list-style-type: none;
    padding: 0;

    li {
      font-weight: normal;
      margin-bottom: 5px;
    }
  }

  .sidebar {
    background: $primaryLight;
  }

  .provider-reviews {
    font-size: 14px;

    a {
      color: #000;
      text-decoration: underline;
    }
  }

  .provider-rating {
    .fa-star {
      color: #fca600;
    }
  }
}
