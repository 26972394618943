@import '../../assets/styles/index';

.InventoryPage {
  padding: 15px 0 30px;

  .search-container {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint('xs') {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .inventory-sidebar {
    padding-left: 0;
    padding-right: 0;
    z-index: 2;

    .inventory-sidebar-wrapper {
      //margin-left: 1rem;
      height: 100%;
    }
  }

  .hz-date {
    color: $primaryDark;
    font-size: 14px;

    span {
      font-weight: bold;
    }
  }

  .load-more {
    border: 0;
    border-bottom: 1px solid $primaryDark;
    border-top: 1px solid $primaryDark;
    color: $buttonDefault;
    padding: 15px 0;
    width: 100%;
  }

  .disclaimer {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

.InventoryPage.SearchPage,
.EducationalPage {
  @media (max-width: 440px) {
    .DateCarousel {
      padding: 0px;
      align-items: center;
    }
  }
}

.private-mode {
  width: 300px;
  margin: 5px auto;
  text-align: center;
  background: #de271b;
  color: white;
  padding: 5px 0;
  z-index: 999;
  box-shadow: 0 1px 1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (min-width: 768px) and (max-width: 1024px) {

  .DateCarouselContainer .dates,
  .DateCarousel .dates {
    width: 800px;
    overflow: scroll;
    flex-wrap: unset;
  }

  .DateCarouselContainer .date-column {
    width: 75px;
  }

  .DateCarouselContainer .date-column.empty {
    min-width: 80px !important;
  }
}