@import '../../assets/styles/index';

.FullSchedule {
  display: flex;
  justify-content: left;
  align-items: center;
  color: $linkDefault !important;
  font-size: 14px;

  svg {
    color: $linkDefault;
    margin-right: 5px;
  }
}
