@import '../../assets/styles/index';
.InventoryTabs {
  display: flex;
  margin: 30px 0;
  padding: 0 24px;

  .tab-item {
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    margin-bottom: -1px;
    padding: 23px 30px 15px;
  }
}
