@import '../assets/styles/index';

.PageError {
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;

  h1 {
    font-size: 4.5rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  h3 {
    color: #505050;
  }

  p {
    color: rgba(#000, 0.6);
  }

  hr {
    border-color: rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
    margin-top: 30px;
    width: 50%;
  }
}
