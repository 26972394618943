$breakpoints: (
  xs: (min: 0px, max: 599px),
  sm: (min: 600px, max: 959px),
  md: (min: 960px, max: 1279px),
  lg: (min: 1280x, max: 1919px),
  xl: (min: 1920px, max: 3000px),
);

@mixin breakpoint($size, $onlyCurrentSize: false) {
  $maxSize: map_get(map_get($breakpoints, lg), max);
  $minSize: map_get(map_get($breakpoints, lg), min);

  @if map-has-key($breakpoints, $size) {
    @if map-has-key(map-get($breakpoints, $size), min) {
      $minSize: map_get(map_get($breakpoints, $size), min);
    }

    @if map-has-key(map-get($breakpoints, $size), max) {
      $maxSize: map_get(map_get($breakpoints, $size), max);
    }
  }

  @if $onlyCurrentSize == true {
    @media screen and (min-width: $minSize) and (max-width: $maxSize) {
      @content;
    }
  } @else {
    @media screen and (max-width: $maxSize) {
      @content;
    }
  }
}

.hide-mobile {
  @include breakpoint('xs') {
    display: none !important;
  }
}

.hide-desktop {
  display: none;

  @include breakpoint('xs') {
    display: block;
  }
}