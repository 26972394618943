.lngContainer {
  padding: 20px 30px 20px 30px;
  text-align: center;
  max-width: 900px !important;
  margin: 0px auto;

  .anchorLng {
    color: #394f8c;
    vertical-align: middle;
    padding: 10px;
  }
  .active {
    .anchorLng {
      //text-decoration: underline;
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .fl-link {
    a {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media (max-width: 440px) {
  .footer-links {
    .fl-link {
      a {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.align-center {
  margin: 0 auto;
}
.plr0 {
  padding: 0 !important;
}
