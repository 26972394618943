@import '../../assets/styles/index';

.Notification {
  z-index: 102;
  position: fixed;
  right: 20px;
  bottom: 20px;

  @include breakpoint('xs') {
    max-width: initial;
    right: 1em;
    left: 1em;
    bottom: 1em;
  }

  .toast {
    box-shadow: 0 3px 6px rgba(#000, 0.4);

    &.show {
      .toast-body {
        padding: 0.75em;
      }
    }

    button {
      display: none;
    }

    .toast-header {
      border: 0;
    }
    .toast-body {
      padding: 0;
    }
  }

  &--warn,
  &--warning {
    .toast {
      background-color: lemonchiffon;
    }
  }

  &--error {
    .toast {
      .toast-header {
        color: white;
      }
      background-color: tomato;
    }
  }

  &--info {
    .toast {
      background-color: cornflowerblue;
    }
  }

  &--success {
    .toast {
      background-color: limegreen;
    }
  }
}
