@import '../../assets/styles/index';
@import '../../assets/styles/index';
.SearchPage,
.educational {
  .DateCarouselContainer {
    .dates {
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin-left: 35px !important;
    }
    .date-column {
      &:last-child {
        padding-right: 0;
        margin-right: 0;
      }
    }
  }
  .date_carousel {
    .DateCarousel {
      flex-wrap: nowrap;
      .DateHeading {
        width: 70px;
        margin-right: 1px;
        padding-left: 1px;
      }
      .dates {
        justify-content: left;
        font-size: 13px;
        align-items: center;
      }
    }
  }
  button {
    &.more {
      &.btn-default {
        color: white;
      }
      border-radius: 0.2rem;
      display: block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.25rem;
      text-align: center;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      user-select: none;
      vertical-align: middle;
      width: 75px;
      -webkit-appearance: button;
    }
  }
}
.educational {
  &.date_carousel {
    .DateCarousel {
      flex-wrap: nowrap;
      .DateHeading {
        width: 75px;

        padding-left: 2px;
        margin: 0 1px 0 5px;
      }
      .dates {
        justify-content: left;
        font-size: 13px;
        align-items: center;
      }
    }
  }
}
.provider {
  .date_carousel {
    .MuiGrid-item {
      align-items: center;
      justify-content: center;
    }
    .DateHeading {
      line-height: 40px;
      margin: 0 5px 0 0px;
      padding-left: 5px;
      width: 75px;
      .day {
        margin-right: 2px;
      }
    }
  }

  .full_schedule {
    justify-content: flex-start;
    display: flex;
    padding-left: 30px;
  }

  .FacilityDateCarousel {
    .DateCarousel {
      border-bottom: 1px solid #000;
      margin-bottom: 15px;
      &.horizontal {
        .DateHeading {
          margin: 0 5px;
          font-size: 13px;
        }
      }
    }
    .dates {
      padding-top: 8px;
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin-left: 25px !important;
    }
  }
  .result {
    > div {
      &:nth-child(2) {
        margin-top: 15px;
      }
    }
  }
  .DateHeading {
    display: flex;
    width: 70px;

    &.vertical {
      text-align: center;
    }

    &.horizontal {
      text-align: left;
      grid-template-columns: auto auto;
    }

    .day {
      font-weight: normal;
      margin-right: 5px;
    }

    .month-day {
      font-weight: bold;
    }
  }
  button {
    &.more {
      &.btn-default {
        color: white;
      }
      border-radius: 0.2rem;
      display: block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.25rem;
      text-align: center;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      user-select: none;
      vertical-align: middle;
      width: 75px;
      -webkit-appearance: button;
    }
  }
  .control {
    &.more {
      margin-left: 33px;
    }
  }
}
.Appointment.Link {
  width: auto;
  display: block;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 70px;
}
