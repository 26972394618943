@import '../../assets/styles/index';

.EducationalPage {
  padding: 15px 0 30px;

  .inventory-sidebar {
    padding-left: 0;
    padding-right: 0;
    z-index: 2;

  }

  .inventory-sidebar-wrapper {
    margin-left: 1rem;
  }

  .search-container {
    margin-bottom: 20px;
    margin-top: 20px;

    @include breakpoint('xs') {
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      max-width: 100%;
    }
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}