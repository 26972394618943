@import '../../assets/styles/index';
.VisitFeedbackPage {
  padding: 30px 0;

  @include breakpoint('xs') {
    padding-left: 15px;
    padding-right: 15px;
  }

  h2,
  .label-question {
    font-weight: bold;
  }

  .errors {
    color: #cc0000;
    padding-bottom: 10px;

    .errors-wrapper {
      padding: 10px 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      display: flex;
      align-items: center;
      font-weight: bold;
      div {
        margin-left: 1em;
        margin-top:3px;
      }
    }
  }
}
