@import '../../assets/styles/index';

.Sitemap {
  background: #f1f1f1;
  border-top: .0625rem solid #dbdbdb;
  color: $primaryDark;
  font-size: 0.875rem;
  padding: 20px 0;

  @include breakpoint('xs') {
    padding-bottom: 100px;
  }

  .facility-name, .facility-number {
    margin: 0;
  }

  .facility-name {
    font-size: 0.75rem;
  }

  .facility-number {
    color: $primaryDark;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 10px;
  }

  h4 {
    border-bottom: 1px solid rgb(218, 217, 223);
    line-height: 1em;
  }
}
