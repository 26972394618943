@import '../../assets/styles/index';

.Header {
  .bottom-navbar {
    font-size: 1rem;
    width: 100%;
    position: relative;

    .container {

      &:before,
      &:after {
        content: ' ';
        display: table;
      }
    }

    .logo {
      height: auto;
      max-width: 300px;
    }
  }

  .site-menu {
    bottom: 0;
    left: 0;
    position: absolute;
  }
}

.MuiMenuItem-root {
  min-height: auto !important;
}

.MuiMenuItem-root {
  font-size: inherit !important;
  color: black !important;
}

// .MuiListItem-button.active{
//   background: #5a70d1;
//     color: #fff!important;
// }

#lang-menu .MuiPaper-root {
  margin-top: 45px;

  @media (orientation:landscape) and (max-width: 900px) {
    right: 5px !important;
    left: unset !important;
  }
}

@media (max-width:678px) {
  .Header .MuiButtonBase-root {
    float: right;
  }
}

.header {
  float: right;
  display: block;
  cursor: pointer;

  @media (orientation:landscape) and (max-width: 844px) {
    float: none;
    text-align: right;
  }
}